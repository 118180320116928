import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import prevArrowIcon from '../images/arrow-left.svg';
import nextArrowIcon from '../images/arrow-right.svg';
import '../styles/work-template.scss';

const WorkTemplate = ({ title, data, location, pageContext }) => {
  const { prev, next } = pageContext;
  // console.log(pageContext);

  return (
    <Layout parentClass={'work'}>
      <Seo title={data.wpWorkItem.title} />
      <section className="container">
        <div className="flex flex-row prev-next-nav">
          {prev && (
            <Link to={`/work/${prev.slug}`}>
              <span>
                <img src={prevArrowIcon} />
                {prev.title}
              </span>
            </Link>
          )}
          {next && (
            <Link to={`/work/${next.slug}`}>
              <span
                style={
                  next
                    ? { justifyContent: 'flex-end' }
                    : { justifyContent: 'flex-start' }
                }>
                {next.title}
                <img src={nextArrowIcon} />
              </span>
            </Link>
          )}
        </div>
      </section>
      <section className="container-fluid hero">
        <div className="container">
          <Img
            fluid={
              data.wpWorkItem.featuredImage.node.localFile.childImageSharp.fluid
            }
            alt={data.wpWorkItem.title}
            className="hero-img"
          />
        </div>
      </section>
      <section className="container">
        <div className="customer">{data.wpWorkItem.acfWork.organization}</div>
        <div className="two-col space-x-20">
          <div className="basis-2/3">
            <h1>{data.wpWorkItem.title}</h1>
            <div className="text-xl mt-8">
              {data.wpWorkItem.acfWork.overview}
            </div>
          </div>
          <div className="basis-1/3">
            <p className="meta role">Role</p>
            <p>
              <strong>{data.wpWorkItem.acfWork.role}</strong>
              <br />
              {data.wpWorkItem.acfWork.skills}
            </p>
            <p className="meta timeline mt-8">Timeframe</p>
            <p>{data.wpWorkItem.acfWork.timeframe}</p>
            {data.wpWorkItem.acfWork.link && (
              <>
                <p className="meta timeline mt-8">Link</p>
                <p>
                  <a href={data.wpWorkItem.acfWork.link} target="_blank">
                    {data.wpWorkItem.acfWork.link}
                  </a>
                </p>
              </>
            )}
          </div>
        </div>
      </section>

      <hr className="container" />

      <section className="container pt-5 text-center">
        <h2>Challenge</h2>
        <p className="m-auto">{data.wpWorkItem.acfWork.problemStatement}</p>
        {data.wpWorkItem.acfWork.problemStatementImage && (
          <Img
            fluid={
              data.wpWorkItem.acfWork.problemStatementImage.localFile
                .childImageSharp.fluid
            }
            fadeIn
            className="mt-10"
          />
        )}
      </section>
      <section className="container-fluid highlight">
        <div className="container text-center">
          <h2>Hypothesis</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: `${data.wpWorkItem.acfWork.hypothesis}`,
            }}></div>
        </div>
      </section>
      <section className="container">
        <h2 className="mb-10">Process</h2>
        <ol className="process">
          {data.wpWorkItem.acfWork.process.map((processItem, index) => (
            <li key={index}>
              {processItem.processImage && (
                <img
                  src={processItem.processImage.sourceUrl}
                  alt={processItem.processImage.alt}
                />
              )}
              <div>
                <h3 className="mb-1">{processItem.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: processItem.description,
                  }}
                />
              </div>
            </li>
          ))}
        </ol>
      </section>

      <hr className="container" />

      <section className="deliverables">
        <div className="container text-center">
          <h2 className="mb-10">Deliverables</h2>
          {data.wpWorkItem.acfWork.deliverablesDescription && (
            <div
              dangerouslySetInnerHTML={{
                __html: `${data.wpWorkItem.acfWork.deliverablesDescription}`,
              }}
              className="mb-10"
            />
          )}
        </div>
        {data.wpWorkItem.acfWork.deliverables.map((deliverable, index) => (
          <div className="deliverable" key={index}>
            <div className="container text-center">
              <h3 className="mb-1">{deliverable.title}</h3>
              {deliverable.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: deliverable.description,
                  }}
                />
              )}
            </div>
            <div
              className={`example ${
                deliverable.highlight === true ? 'highlight' : ''
              }`}>
              <div className="container">
                {deliverable.deliverableImage && (
                  <img
                    src={deliverable.deliverableImage.sourceUrl}
                    alt={deliverable.deliverableImage.alt}
                  />
                )}
                {deliverable.deliverableCaption && (
                  <div className="caption">
                    <div className="basis-1/2">
                      <p className="text-sm">
                        {deliverable.deliverableCaption}
                      </p>
                    </div>
                    <div className="basis-1/2">
                      <img
                        src={deliverable.deliverableCaptionImage.sourceUrl}
                        alt={deliverable.deliverableCaptionImage.alt}
                      />
                    </div>
                  </div>
                )}
                {!deliverable.deliverableCaption &&
                  deliverable.deliverableCaptionImage && (
                    <div className="caption-img-only">
                      <img
                        src={deliverable.deliverableCaptionImage.sourceUrl}
                        alt={deliverable.deliverableCaptionImage.alt}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        ))}
      </section>
      <section>
        <div className="container text-center">
          <h2>Outcome</h2>
        </div>
        {data.wpWorkItem.acfWork.outcome.map((outcomeItem, index) => (
          <div key={index}>
            <div
              className={`container outcome ${
                outcomeItem.highlight === true ? 'highlight' : ''
              }`}>
              {outcomeItem.outcomeLayout === 'Two Column' && (
                <div className="two-col">
                  <div className="basis-1/2 pr-10">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: outcomeItem.description,
                      }}
                      className="basis-1/2 pr-20"
                    />
                    {outcomeItem.outcomeImage && (
                      <img
                        src={outcomeItem.outcomeImage.sourceUrl}
                        alt={outcomeItem.outcomeImage.alt}
                      />
                    )}
                  </div>
                  <div className="basis-1/2">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: outcomeItem.outcomeCaption,
                      }}
                    />
                  </div>
                </div>
              )}

              {outcomeItem.outcomeLayout === 'One Column' && (
                <div>
                  {outcomeItem.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: outcomeItem.description,
                      }}
                      className="basis-1/2"
                    />
                  )}
                  {outcomeItem.outcomeImage && (
                    <img
                      src={outcomeItem.outcomeImage.sourceUrl}
                      alt={outcomeItem.outcomeImage.alt}
                    />
                  )}
                  {outcomeItem.outcomeCaption && (
                    <div className="caption">
                      <div className="basis-2/3">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: outcomeItem.outcomeCaption,
                          }}
                        />
                      </div>
                      {outcomeItem.outcomeImage && (
                        <div className="basis-1/3">
                          <img
                            src={outcomeItem.outcomeCaptionImage.sourceUrl}
                            alt={outcomeItem.outcomeCaptionImage.alt}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </section>
    </Layout>
  );
};
export default WorkTemplate;

export const query = graphql`
  query ($id: String!) {
    wpWorkItem(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          id
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      acfWork {
        overview
        themeColor
        organization
        role
        skills
        timeframe
        link
        problemStatement
        problemStatementImage {
          id
          sourceUrl
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        hypothesis
        process {
          title
          description
          processImage {
            id
            title
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
                fixed(width: 600) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }

        deliverablesDescription
        deliverables {
          title
          description
          deliverableImage {
            id
            title
            sourceUrl
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 600) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          deliverableCaption
          deliverableCaptionImage {
            id
            title
            sourceUrl
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
                fixed(width: 600) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          highlight
        }

        outcome {
          outcomeLayout
          description
          outcomeImage {
            id
            title
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
                fixed(width: 600) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          outcomeCaption
          outcomeCaptionImage {
            id
            title
            sourceUrl
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
                fixed(width: 600) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          highlight
        }
      }
    }
  }
`;
